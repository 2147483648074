import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import * as icons from "@coreui/icons-pro";

import { Provider } from "react-redux";
import store from "./store";

import axios from "axios";

React.icons = icons;

axios.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});
axios.defaults.baseURL = "https://fastcharge.kr:3000";
// axios.defaults.baseURL = "http://52.78.28.48:5000";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
